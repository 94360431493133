<template>
    <el-table v-if="data.length > 0" :data="data" style="width: 100%">
        <el-table-column width="55">
            <template slot-scope="scope">
                <el-popover
                    v-if="scope.row.image_src"
                    placement="top-start"
                    width="250"
                    trigger="hover"
                >
                    <img :src="scope.row.image_src" class="h-56 w-56 rounded-full">
                    <img slot="reference" :src="scope.row.image_src" class="h-8 w-8 rounded-full">
                </el-popover>
            </template>
        </el-table-column>
        <el-table-column prop="name" width="100" :label="$t('keys.number')" sortable :sort-method="nameSort" />
        <el-table-column prop="description" :label="$t('keys.description')" sortable :show-overflow-tooltip="true" />
        <el-table-column prop="client" :label="$t('keys.client')" sortable :show-overflow-tooltip="true" />
        <el-table-column prop="address" :label="$t('keys.address')" sortable :show-overflow-tooltip="true" />
        <el-table-column prop="holder" :label="$t('keys.holds')" sortable :show-overflow-tooltip="true">
            <template slot-scope="scope">
                <router-link
                    v-if="scope.row.holder_uuid"
                    :to="`/employees/${scope.row.holder_uuid}/information`"
                    class="hover:text-red-500 hover:font-semibold"
                >
                    {{ currentHolderFormatter(scope.row.holder) }}
                </router-link>
                <p v-else>
                    {{ currentHolderFormatter(scope.row.holder) }}
                </p>
            </template>
        </el-table-column>
        <el-table-column width="35">
            <template slot-scope="scope">
                <el-tooltip
                    v-if="scope.row.replacement"
                    :enterable="false"
                    :openDelay="500"
                    class="item"
                    effect="dark"
                    :content="scope.row.replacement"
                    placement="left"
                >
                    <fa-icon :icon="['far', 'sync']" />
                </el-tooltip>
            </template>
        </el-table-column>
        <el-table-column width="130">
            <template slot-scope="scope">
                <el-button-group>
                    <el-tooltip :enterable="false" :openDelay="500" effect="dark" :content="$t('keys.history')" placement="left">
                        <el-button type="primary" size="mini" @click="handleKeyHistory(scope.row.uuid)">
                            <fa-icon :icon="['far', 'history']" />
                        </el-button>
                    </el-tooltip>
                    <el-tooltip :enterable="false" :openDelay="500" effect="dark" :content="$t('keys.change_holder')" placement="top">
                        <el-button type="primary" size="mini" :disabled="!userCan('update keys')" @click="handleEditHolder(scope)">
                            <fa-icon :icon="['far', 'sync']" />
                        </el-button>
                    </el-tooltip>
                    <el-tooltip :enterable="false" :openDelay="500" effect="dark" :content="$t('common.edit')" placement="top">
                        <el-button type="primary" size="mini" :disabled="!userCan('update keys')" @click="handleEdit(scope)">
                            <fa-icon :icon="['far', 'edit']" />
                        </el-button>
                    </el-tooltip>
                    <el-tooltip :enterable="false" :openDelay="500" effect="dark" :content="$t('clients.archive')" placement="top">
                        <el-button type="warning" size="mini" :disabled="!userCan('update keys')" @click="handleArchive(scope.row.uuid)">
                            <fa-icon :icon="['fas', 'archive']" fixed-width />
                        </el-button>
                    </el-tooltip>
                </el-button-group>
            </template>
        </el-table-column>
    </el-table>
</template>

<script>
export default {
    props: {
        data: {
            type: Array,
            default() {
                return [];
            },
        },
    },

    methods: {
        currentHolderFormatter(holder) {
            if (holder === 'office' || holder === 'client') {
                return this.$t(`keys.at_${holder}`);
            }
            return holder;
        },

        handleKeyHistory(value) {
            this.$emit('handleKeyHistory', value);
        },

        handleEditHolder(value) {
            this.$emit('handleEditHolder', value);
        },

        handleEdit(value) {
            this.$emit('handleEdit', value);
        },

        handleArchive(value) {
            this.$emit('handleArchive', value);
        },

        nameSort(a, b) {
            return a.name - b.name;
        },
    },
};
</script>
